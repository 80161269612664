import './App.scss';
import logo from "./logo.png";
import { useState, useEffect } from 'react';

function App() {
  const [carData, setCarData] = useState({});

  useEffect(() => {
    const importAll = (r) => {
      return r.keys().map((filename) => {
        const [category, vehicle] = filename.split('_');
        return {
          category,
          vehicle: vehicle.split('.')[0],
          src: r(filename),
        };
      });
    };
    const images = importAll(require.context('./images', false, /\.(jpeg)$/));
    const groupedData = groupByCategory(images);
    setCarData(groupedData);
  }, []);

  function groupByCategory(images) {
    const groupedData = {};
    images.forEach((image) => {
      if (!groupedData[image.category]) {
        groupedData[image.category] = [];
      }
      groupedData[image.category].push({
        vehicle: image.vehicle,
        src: image.src,
      });
    });
    return groupedData;
  }

  return (
    <div className="App">
      <div className="header">
        <img src={logo} alt="LifeV Logo" className="logo"/>
        <div className="label">
          LifeV Roleplay
        </div>
        <div className="name">Importfahrzeuge</div>
      </div>
      {Object.entries(carData).map(([category, vehicles]) => 
        <div className="category">
          <div className="label">{category.replace("./", "")}</div>
          <div className="cars">
            {vehicles.map((vehicle, index) =>
              <div className="car" key={index} style={{backgroundImage: `url(${encodeURI(vehicle.src)})`}}>
                <div className="overlay">{vehicle.vehicle}</div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="footer">
        coded with ❤️ by <a href="https://github.com/matthias-codes">matthias-codes</a>
      </div>
    </div>
  );
}

export default App;
